import React, {useRef} from 'react'
import { motion, useInView } from "framer-motion"

function Introduction () {
    const ref = useRef(null)
    const isInView = useInView(ref)

    return (
        <div ref={ref}>
            {/* Introduction */}
            <section className="intro">
                <div id="intro-container">
                    <motion.img src="img/naftaly.jpg" alt="Naftaly" className="intro__img" style={{ 
                            transform: isInView ? "none" : "translateX(-500px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                            transitionDelay: "1s"}}
                    />
                    <motion.h1 className="section__title section__title--intro" style={{ 
                            transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                            transitionDelay: "1.75"                    
                        }}
                    >
                        Hi, I'm <strong>Naftaly Sirota</strong>
                    </motion.h1>
                    
                    <motion.p className="section__subtitle--intro" style={{ 
                            transform: isInView ? "translateX(0)" : "translateX(-300px)",
                            width: isInView ? "100%" : "20%",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                            transitionDelay: ".75s"}}
                    >
                        Software Developer
                    </motion.p>
                </div>
            </section>
        </div>
    )
}

export default Introduction