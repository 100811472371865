import React, {useState, useEffect} from 'react'
import { motion, useScroll, useMotionValueEvent } from "framer-motion"
import { NavLink } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import Logo from './Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseChimney, faLaptopCode, faAddressCard, faFileWord, faFileCode, faCommentDots} from '@fortawesome/free-solid-svg-icons'

function Nav() {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [windowSize, setWindowSize] = useState(undefined)
    const [style, setStyle] = useState("hamburger")
    const [navBar, setNavBar] = useState("nav")
    const [logoScale, setLogoScale] = useState(1)
    const [navLeft, setNavLeft] = useState(0)

    
    useEffect (() => {
        function handleResize() {
            setWindowSize(window.innerWidth);
            if(window.innerWidth > 480 ) {
                setIsNavOpen(true)
            } else {
                setIsNavOpen(false)
                setNavBar("nav")
                setLogoScale(1)
                setNavLeft(1)
            }
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [windowSize])
    
    useEffect (() => {
        if (isNavOpen) { 
            setStyle("hamburgerx")
        } else { 
            setStyle("hamburger")
        }
    },[isNavOpen]) 
    
    function CloseNav() {
        if (window.innerWidth <= 480) {
            setTimeout(()=> setIsNavOpen(false), 400)
        }
    }
    
    function TopofPage() {
        window.scrollTo({ top: 0, left: 0 }) //, behavior: 'smooth'
    }
    
    const variants = {
        open: { opacity: 1, x: "0" },
        closed: { opacity: 0, x: "110%" },
    }

    const { scrollY } = useScroll()
        useMotionValueEvent(scrollY, "change", (position) => {
            if (position >= 80 && window.innerWidth > 480) {
                setNavBar("nav-fixed")
                setNavLeft(0)
            } else if (position < 80 && window.innerWidth > 480) {
                setNavBar("nav")
                setLogoScale(1 - (position / 80))
                if (window.innerWidth > 1125) {
                    setNavLeft(position / 80 * 5)
                }
            }
    })
        
    const openNav =
        <motion.nav className={navBar} 
            initial={{ opacity: 0 }}
            animate={isNavOpen ? "open" : "closed"}
            variants={variants}
            transition={{ duration: 0.5 }}
            style={{left: `-${navLeft}em`}}
        > 
            <ul className="nav__list">
                <li className="nav__item" onClick={()=> { CloseNav(); TopofPage() }}>
                    <NavLink to="/" className="nav__link nav_link_underline">Home</NavLink>
                    <div className="nav__icon" id="nav_home"><FontAwesomeIcon icon={faHouseChimney} /></div>
                </li>
                <li className="nav__item" onClick={()=> CloseNav()}>
                    <HashLink to="/#services" className="nav__link">My Services</HashLink>
                    <div className="nav__icon" id="nav_services"><FontAwesomeIcon icon={faLaptopCode} /></div>
                </li>
                <li className="nav__item" onClick={()=> CloseNav()}>
                    <HashLink to="/#about" className="nav__link">About me</HashLink>
                    <div className="nav__icon" id="nav_about"><FontAwesomeIcon icon={faAddressCard} /></div>
                </li>
                <li className="nav__item" onClick={()=> { CloseNav(); TopofPage() }}>
                    <NavLink to="/resume" className="nav__link nav_link_underline">My Resume</NavLink>
                    <div className="nav__icon" id="nav_resume"><FontAwesomeIcon icon={faFileWord} /></div>
                </li>
                <li className="nav__item" onClick={()=> CloseNav()}>
                    <HashLink to="/#work" className="nav__link">My Work</HashLink>
                    <div className="nav__icon" id="nav_mywork"><FontAwesomeIcon icon={faFileCode} /></div>
                </li>
                <li className="nav__item" onClick={()=> { CloseNav(); TopofPage() }}>
                    <NavLink to="/contact" className="nav__link nav_link_underline">Contact</NavLink>
                    <div className="nav__icon" id="nav_contact"><FontAwesomeIcon icon={faCommentDots} /></div>
                </li>
            </ul>
        </motion.nav>

    return (
        <div>
            <header id="home">
                <div id="header-grid-container">
                    <div className="nav-toggle" aria-label="toggle navigation" onClick={()=> setIsNavOpen(!isNavOpen)}>
                        <span className={style}></span>
                    </div>
                    <div className="header-grid" id="header-grid-logo" onClick={()=> { CloseNav(); TopofPage() }}><NavLink to="/" id="nav_logo_link"><Logo scale={logoScale} /></NavLink></div>
                    <div className="header-grid" id="header-grid-bsd"><img src="img/bh_svg.svg" alt='B"H' /></div>
                    <div className="header-grid" id="header-grid-nav">{openNav}</div>
                </div>
            </header>
        </div>
    )
}

export default Nav