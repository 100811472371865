import React from 'react'

function Resume() {
    return (
        <div>

            <iframe id="pdf_file" src="img/resume_react_js_naftaly.pdf" title="resume_react_js_naftaly.pdf"></iframe>
        </div> //height="500" width="100%" frameBorder="0"
    )
}

export default Resume