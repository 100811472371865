import React, {useRef} from 'react'
import { motion, useInView } from "framer-motion"

function Services () {
    const ref = useRef(null)
    const isInView = useInView(ref)

    return (
        <div ref={ref}>
            {/* My services */}
            <section className="my-services" id="services">
                <motion.h2 className="section__title section__title--services" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s"}}
                >What I Do
                </motion.h2>

                <motion.p className="section__subtitle section__subtitle--services" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1s"}}
                >
                    Web and Mobile App Developer
                </motion.p>

                <div className="services">
                    <motion.div className="service" style={{ 
                        transform: isInView ? "none" : "translateX(-500px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1.25s"}}
                >
                        <h3>User Interface</h3>
                        <p>I maintain and improve websites by developing new user-facing and mobile-based features. 
                            I also translate creative software design concepts and ideas into reality, 
                            combining the art of design with the art of programming. 
                            I convert UI/UX design wireframes to actual code that produces visual elements of the application.
                        </p>
                    </motion.div> {/* end of service */}

                    <motion.div className="service" style={{ 
                        transform: isInView ? "none" : "translateX(-600px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1s"}}>
                        <h3>Responsive Web Design</h3>
                        <p>I create web pages that look good on all devices, with the best experience to users, 
                            whether they’re on their desktop, laptop, tablet, smartphone, or even Smart TV!
                        </p>
                    </motion.div> {/* end of service */}
               
                    <motion.div className="service" style={{ 
                        transform: isInView ? "none" : "translateX(-700px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s"}}>
                        <h3>Performance Optimization</h3>
                        <p>I use React.js which is known as one of the fastest web development frameworks out there. 
                            It was designed to provide high performance in mind which makes complex apps run extremely fast. 
                            Internally, when I use React which handles several clever techniques, 
                            I minimize the number of costly DOM operations required to update the UI. 
                            For many applications, my use with React will lead to a faster user interface.
                        </p>
                    </motion.div> {/* end of service */}
                </div> {/* end of services */}
            </section>
        </div>
    )
}

export default Services