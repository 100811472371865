import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com'

function Contact() {
    const form = useRef()
    
    const {
        register,
        watch,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    
    function submitHandler(e) {
        // e.preventDefault();
        emailjs.sendForm('service_qtkcla3', 'template_tl4uklu', form.current, 'Ouubab2vkqEEzdk2H')
        .then(() => {
            Swal.fire({
                title: `<div style="font-family:Lora,serif;">Hey ${e.name}!</div>`,
                html: 'Thank you for contacting me.<br />I will try to get back to you as soon as possible.',
                icon: 'success',
                confirmButtonText: 'OK'
              })
            reset()
            // console.log(result.text)
        }, (error) => {
            alert(error.text);
        });
    }
    
    
    return (
        <div>
            <section className="form_contact">
                <form ref={form} onSubmit={handleSubmit(submitHandler)}>
                    <h2>Let's get in touch {watch('name')}</h2>

                    {errors.name && <div className='error_message'>*Name is required.</div>}
                    <input {...register('name', { required: true })} placeholder='First name' />
                    <input {...register('tel', { required: false })} placeholder='Phone number' />
                    {errors.email && <div className='error_message'>*Email is required.</div>}
                    <input {...register('email', { required: true })} placeholder='Email address' />
                    {errors.message && <div className='error_message'>*Your message is required.</div>}
                    <textarea {...register('message', { required: true })} rows='8' placeholder='Your Message'></textarea>
                    <button type='submit'>Send</button>
                </form>
            </section>
        </div>
    )
}

export default Contact