import React from 'react'

function Logo({scale}) {
    return (
        <div id="logo" style={{transform: `scale(${scale})`, opacity: scale}}>
            <div id="logo-image"><img src="img/portfolio_logo.svg" alt="portfolio logo" /></div>
            <div id="logo-description">
                <div id="logo-software" className='logo-description'>Software</div>
                <div id="logo-developer" className='logo-description'>Developer</div>
            </div>
        </div>
    )
}

export default Logo