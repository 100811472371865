import Introduction from '../components/introduction'
import Services from '../components/services'
import About from '../components/about'
import MyWork from '../components/my_work'

function Home() {
    return (
        <div>
            <Introduction />
            <Services />
            <About />
            <MyWork />
        </div>
    )    
}

export default Home;
