import React, {useRef} from 'react'
import { motion, useInView } from "framer-motion"

function About () {
    const ref = useRef(null)
    const isInView = useInView(ref)

    return (
        <div ref={ref}>
            {/* About me */}
            <section className="about-me" id="about">
                <motion.h2 className="section__title section__title--about" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s"}}
                >
                    Who I Am
                </motion.h2>
                <motion.p className="section__subtitle section__subtitle--about" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1s"}}
                >
                    Designer & developer based out of NYC
                </motion.p>
           
                <motion.div className="about-me__body" style={{ 
                    transform: isInView ? "scale(1, 1)" : "scale(0.75, 0.75)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                    transitionDelay: "1.25s"}}
                >
                    <p>My name is Naftaly Sitota. I graduated from Touro University, majoring in Management Information System in computer programming. 
                        I live in Brooklyn, NY, married, and have five wonderful kids. 
                        I am a front-end developer, and well-versed in all aspects of React development. 
                        I set up the environment, creating components using React Hooks with Redux and Context.
                    </p>
                    <p>In addition to my React development skills, I am also an expert in HTML, CSS, and JavaScript. 
                        I have a deep understanding of the latest web development trends and technologies.
                    </p>
                </motion.div>
            </section>
        </div>
    )
}

export default About