import React from 'react'
import { NavLink } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faMessage } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

function Footer() {
    return (
        <div>
            <footer className="footer">
                <div className="footer-grid-container">
                    <div className="footer-my-work">
                        <h3>My Work</h3>
                        <h4>Work pages</h4>
                        <ul>
                            <li><a href="portfolio-item.html" target={"_blank"}>Example 1</a></li>
                            <li><a href="portfolio-item.html" target={"_blank"}>Example 2</a></li>
                            <li><a href="portfolio-item.html" target={"_blank"}>Example 3</a></li>
                            <li><a href="portfolio-item.html" target={"_blank"}>Example 4</a></li>
                        </ul>
                    </div>

                    <div className="footer-sitemap">
                        <h3>Sitemap</h3>
                        <h4>My pages</h4>
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><HashLink to="/#services">My Services</HashLink></li>
                            <li><HashLink to="/#about">About me</HashLink></li>
                            <li><NavLink to="/resume">My Resume</NavLink></li>
                        </ul>
                    </div>

                    <div className="footer-contat-me">
                        <h3>Contact Me</h3>
                        <h4>Send me a message</h4>
                        <ul>
                            <li><div className="icon"><FontAwesomeIcon icon={faPhone} /></div>
                                <a href="tel:+1718-781-4002">718-781-4002</a>
                            </li>
                            <li><div className="icon"><FontAwesomeIcon icon={faEnvelope} /></div> 
                                <a href="mailto:naftalyhs@gmail.com">naftalyhs@gmail.com</a>
                            </li>
                            <li><div className="icon"><FontAwesomeIcon icon={faMessage} /></div>
                            <NavLink to="/contact">Message Me</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                    <div className="footer-social-list">
                        <ul className="social-list">
                            <li><div className="icon">
                                    <a className="social-list__link" href="https://www.linkedin.com/in/naftaly-sirota" target={"_blank"} rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                </div>
                            </li>    
                            <li><div className="icon">
                                    <a className="social-list__link" href="https://github.com/naftalyhs" target={"_blank"} rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faGithub} />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                <div className="copyright">&copy; 2023 All rights reserved to Naftaly H. Sirota</div>
            </footer>
        </div>
    )
}

export default Footer