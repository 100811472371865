import React, {useRef} from 'react'
import { motion, useInView } from "framer-motion"

function MyWork () {
    const ref = useRef(null)
    const isInView = useInView(ref)

    return (
        <div ref={ref}>
            {/* My Work */}
            <section className="my-work" id="work">
                <h2 className="section__title section__title--work" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s"}}
                >My Work</h2>
                <p className="section__subtitle section__subtitle--work" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1s"}}
                >A selection of my range of work and testimonial</p>
           
                <div className="portfolio">
                    {/* Portfolio item 01 */}
                    <motion.div className="portfolio-card" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1.25s"}}
                    >
                        <div className="portfolio-img-container">
                            <a href="portfolio-item.htm" className="portfolio__item" target={"_blank"}>
                                <img src="img/portfolio-01.jpg" alt="" className="portfolio__img" />
                            </a>
                        </div>
                        <div className="testimonial">Coming soon...</div>
                    </motion.div>
                    
                    {/* Portfolio m('item') 02 */}
                    <motion.div className="portfolio-card" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1.25s"}}
                    >
                        <div className="portfolio-img-container">
                            <a href="portfolio-item.html" className="portfolio__item" target={"_blank"}>
                                <img src="img/portfolio-02.jpg" alt="" className="portfolio__img" />
                            </a>
                        </div>
                        <div className="testimonial">Coming soon...</div>
                    </motion.div>
                    
                    {/* Portfolio item 03 */}
                    <motion.div className="portfolio-card" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1.25s"}}
                    >
                        <div className="portfolio-img-container">
                            <a href="portfolio-item.html" className="portfolio__item" target={"_blank"}>
                                <img src="img/portfolio-03.jpg" alt="" className="portfolio__img" />
                            </a>
                        </div>
                        <div className="testimonial">Coming soon...</div>
                    </motion.div>
                    
                    {/* Portfolio item 04 */}
                    <motion.div className="portfolio-card" style={{ 
                        transform: isInView ? "scale(1, 1)" : "scale(0.5, 0.5)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.75s",
                        transitionDelay: "1.25s"}}
                    >
                        <div className="portfolio-img-container">
                            <a href="portfolio-item.html" className="portfolio__item" target={"_blank"}>
                                <img src="img/portfolio-04.jpg" alt="" className="portfolio__img" />
                            </a>
                        </div>
                        <div className="testimonial">Coming soon...</div>
                    </motion.div>
                </div>
            </section>
        </div>
    )
}

export default MyWork