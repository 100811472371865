import React from "react"
import './App.css'
import { Routes, Route } from "react-router-dom"
import Nav from './components/nav'
import Home from "./pages/home"
import Resume from './pages/resume'
import Contact from "./pages/contact"
import Footer from './components/footer'

function App() {
    return (
        <div className="App">
            <Nav />
            <Routes>
                <Route path="/" element={ <Home /> } />
                <Route path="/resume" element={ <Resume /> } />
                <Route path="/contact" element={ <Contact /> } />
            </Routes>
            <Footer />
        </div>
    )
}

export default App
